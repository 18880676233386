@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;300;400;500;600;700;800;900&display=swap');
@font-face {
    font-family:'Big Caslon';
    src:url('../fonts/BigCaslon-Medium.eot');
    src:url('../fonts/BigCaslon-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BigCaslon-Medium.woff2') format('woff2'),
        url('../fonts/BigCaslon-Medium.woff') format('woff'),
        url('../fonts/BigCaslon-Medium.ttf') format('truetype'),
        url('../fonts/BigCaslon-Medium.svg#BigCaslon-Medium') format('svg');
    font-weight:500;
    font-style:normal;
    font-display:swap;
}
html{font-size:62.5%;height:100%;}
body{font-family:'Public Sans', sans-serif;font-weight:300;font-style:normal;box-sizing:border-box;position:relative;font-size:1.4rem;line-height:24px;color:#ffffff;}
a, a:hover, a:focus, a:active{text-decoration:none;outline:0;}
input, input:focus, input:hover, input:active{outline:0;box-shadow:none;}
ul{margin:0 auto;padding:0;list-style:none;}
button, button:hover, button:active, button:focus{box-shadow:none;outline:0;border:0;}

#nav-icon {position: relative;width:30px;height: 18px;cursor: pointer;z-index: 98; display:none;}
#nav-icon span {display: block;position: absolute;height:2px;width: 50%;background:#ffffff;opacity: 1;-webkit-transform: rotate(0);-moz-transform: rotate(0);-o-transform: rotate(0);transform: rotate(0);-webkit-transition: all .35s ease-in-out;-moz-transition: all .3s ease-in-out;-o-transition: all .3s ease-in-out;transition: all .3s ease-in-out;}
#nav-icon span:nth-child(even) {left: 50%;}
#nav-icon span:nth-child(odd) {left: 0;}
#nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {top: 0;}
#nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {top: 6px;}
#nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {top: 12px;}
#nav-icon span:nth-child(7), #nav-icon span:nth-child(8) {top: 18px;}
#nav-icon.open span:nth-child(1), #nav-icon.open span:nth-child(8) {-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);-o-transform: rotate(45deg);transform: rotate(45deg);}
#nav-icon.open span:nth-child(2), #nav-icon.open span:nth-child(7) {-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);}
#nav-icon.open span:nth-child(1) {left: 1px;top: 2px;}
#nav-icon.open span:nth-child(2) {left: calc(50% - 6px);top: 2px;}
#nav-icon.open span:nth-child(3) {left: -50%;opacity: 0;}
#nav-icon.open span:nth-child(4) {left: 100%;opacity: 0;}
#nav-icon.open span:nth-child(5) {left: -50%;opacity: 0;}
#nav-icon.open span:nth-child(6) {left: 100%;opacity: 0;}
#nav-icon.open span:nth-child(7) {left: 1px;top: 9px;}
#nav-icon.open span:nth-child(8) {left: calc(50% - 6px);top: 9px;}

.header{position:absolute;top:0;left:0;width:100%;z-index:99;padding:20px;}
.logoWrap a{font-family:'Big Caslon';color:#ffffff;font-size:2rem;}
ul.navList{display:flex;flex-flow:row wrap;justify-content:flex-end;align-items:center;}
ul.navList li{margin:0 15px;}
ul.navList li:last-child{margin-right:0;}
ul.navList li a{color:#ffffff;font-size:1.1rem;text-transform:uppercase;letter-spacing:0.6px;font-weight:500;}
ul.navList li.getAppBtn a{color:#262526;background-color:#ffffff;border-radius:5px;-webkit-border-radius:5px;padding:10px 15px;}
.bannerWrap{background-image:url(../img/banner.png);background-repeat:no-repeat;background-size:cover;background-position:center center;height:70vh;position:relative;display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.bannerWrap:after{content:"";width:100%;height:100%;background-color:rgba(0,0,0,0.5);position:absolute;top:0;left:0;}
.bannnerTitlWrap{position:relative;z-index:9;text-align:center;}
.bannnerTitlWrap p{text-transform:uppercase;font-size:1.2rem;font-weight:600;letter-spacing:0.6px;margin:0 auto;}
.bannnerTitlWrap h1{font-family:'Big Caslon';margin:0 auto;font-size:3.8rem;line-height:38px;}
.tabNavWrapper{background-color:#000000;padding:50px 0;}
.nav-tabs{border:0;display:flex;align-items:center;justify-content:center;}
.nav-tabs li{margin:0 15px;}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active{border:0;border-radius:5px;background-color:#ffffff;color:#262526;}
.nav-tabs li a{border-radius:5px;border:1px solid #FFFFFF!important;padding:6px 15px;text-transform:uppercase;font-size:1.1rem;font-weight:500;letter-spacing:0.4px;color:#ffffff;min-width:110px;text-align:center;} 
.tab-content{padding-top:30px;}
.tab-content .imgWrapper{padding:30px;}
.tab-content .dataWrapper{padding:30px 10px;}
.tab-content .dataWrapper h6{color:#ffffff;font-size:1.1rem;text-transform:uppercase;letter-spacing:0.6px;font-weight:500;}
.tab-content .dataWrapper h2{font-family:'Big Caslon';font-size:2.8rem;margin:10px 0;}
.tab-content .dataWrapper p{color:#8B8B8B;letter-spacing:0.2px;margin:0;}

.accountRemoveWrap{background-color:#000000;height:100vh;position:relative;padding:20px 10px;}
.accountRemoveWrap .logoWrapper{position:absolute;left:50%;transform:translateX(-50%);}
.accountRemoveWrap .logoWrapper a{letter-spacing:0.02em;color:#FFFFFF;font-family:'Big Caslon';font-size:1.8rem;}
.accountRemoveWrap .middleDataWrap{height:96vh;display:flex;align-items:center;justify-content:center;flex-flow:column;}
.accountRemoveWrap .linksWrap ul{display:flex;align-items:center;justify-content:center;}
.accountRemoveWrap .linksWrap ul li{width:44px;height:44px;border-radius:10px;align-items:center;justify-content:center;display:flex;margin:0 5px;}
.accountRemoveWrap .linksWrap ul li a{width:100%;height:100%;display:flex;align-items:center;justify-content:center;color:#FFFFFF;font-size:2rem;flex-flow:row wrap;}
.accountRemoveWrap .linksWrap ul li.logoLink{border:1px solid #ffffff;}
.accountRemoveWrap .linksWrap ul li.logoLink a{font-family:'Big Caslon';}
.accountRemoveWrap .linksWrap ul li.facebookLink{background-color:#1877f2;}
.accountRemoveWrap .middleDataWrap .dataWrap{margin:15px auto;text-align:center;}
.accountRemoveWrap .middleDataWrap .dataWrap h2{font-family:'Big Caslon';color:#ffffff;font-size:2.4rem;}
.accountRemoveWrap .middleDataWrap .dataWrap p{color:#8B8B8B;margin:0 auto;font-size:1.2rem;letter-spacing:0.2px;}
.accountRemoveWrap .middleDataWrap .cancelCtaWrap{margin:30px auto 0;}
.accountRemoveWrap .middleDataWrap .cancelCtaWrap a{color:#262526;background-color:#ffffff;font-size:1.2rem;font-weight:600;letter-spacing:0.2px;padding:8px 15px;border-radius:8px;}

ul.navList a img {
    width: 25px;
}